export const stagingTenants = {
  '2cc8583f-bee1-42cb-afc0-1f7063696a83': 'Sportsbook',
  '5997b951-c503-412f-91fb-c5226c8b767e': 'b2btest',
  '0c17fe55-315e-4ee0-b4f6-1364c8557dd1': 'WombBigi',
  '89b6ea63-48a1-48e0-aa65-68e53113e3eb': 'Wayyy',
  '8c81ee12-ccc9-41fd-8395-f76250c21933': 'IDNSports',
  '56519aeb-9c3d-4802-8277-721fdddc2621': 'Kingroyal',
  'c27ede72-69f6-422f-a90c-390505dff908': 'Okada',
  'c5455483-c72c-4bb7-8620-78b778a458a1': 'Oxplay',
  '94f72bfb-c194-434d-936e-fd96e366cce9': 'Vinn',
  'c8fa6e81-771b-459c-9a10-1ff8e8e5be8a': 'BetR9',
};

export const productionTenants = {
  '99549254-1525-413c-8db2-d9652a777bd6': 'NSoft',
  '5556ccf6-ea0c-46a5-a777-e532237012de': 'Showcase',
  'c78c8828-a621-4a6c-9e77-1a5ba8a72da8': 'Kingroyal',
  'e8ba2d2c-d3a4-4f8f-a6ac-abd817c9628a': 'Meritking',
  '8c5c6f69-48f8-436c-8b7e-8fcc6af0d93d': 'Wayyy',
  '594d3341-c68e-46d0-9a63-03f41e9003c9': 'IDNSports',
  'd7ae83cb-00ae-4e32-a795-71e29691d440': 'Oxplay',
  '5b77e3dc-ed69-424e-9472-a377dafd7249': 'Okada',
  'ff0093ba-3fe4-4918-a4bf-a5f3117c6bb9': 'BetR9',
  'c32f5d09-40e6-4287-a84f-6e543f5d1419': 'Vinn',
};

export default {
  ...stagingTenants,
  ...productionTenants,
};
